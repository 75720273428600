<template>
  <v-container
    id="chat"
    fluid
    tag="section"
  >
    <div>
      <iframe
        :src="url"
        :name="Date.now()"
        style="width: 100%; height: calc(100vh - 120px); border: none;"
        allow="geolocation; microphone; camera; notifications;"
      ></iframe>
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import Utils from '@/services/Utils'

  export default {
    data () {
      return {
        url: axios.defaults.chatWebviewUrl + 'is_pt=true&theme=light&audio=true' +
          '&pt_id=' + axios.defaults.ptId +
          '&id=' + axios.defaults.ptId +
          (this.$route.params.clientChatId ? ('&chat_user_id=' + this.$route.params.clientChatId) : '') +
          '&log_id=' + axios.defaults.userId +
          '&log_email=' + axios.defaults.chatLogEmail +
          '&log_name=' + axios.defaults.chatLogName +
          '&dashboard_client_url=true&lng=' + window.language +
          '&timestamp=' + new Date().getTime()
      }
    },
    beforeMount: function () {
      Utils.refreshNewMessagesCounter(0)
    },
  }
</script>
